
import { Component, Vue } from 'vue-property-decorator'
import { api } from '@/api'
import '@/assets/css/custom.css'
import '@/assets/css/style.min.css'
import Spinner from '@/components/Spinner.vue'
import { WithdrawConfirmType } from '@/interfaces/wallet.type'

@Component({
  components: { Spinner },
})
export default class WalletConfirm extends Vue {

  public loading: boolean = false
  public message: string = ''

  async beforeMount() {
    const code = this.$route.query.code
    const id = this.$route.query.id

    console.debug('%c CODE: %s ID: %s', 'color:lime;', code, id)

    if (!!code && !!id) {
      this.message = this.$t('OperationError').toString()

      try {
        this.loading = true
        const data: WithdrawConfirmType = {
          withdraw_request: id.toString(),
          confirm_code: code.toString(),
        }

        const result = await api.withdrawConfirm(data)

        if (result.data) this.message = this.$t('WithdrawalConfirmed').toString()

      } catch (err) {
        console.error('%c WalletConfirm.vue onBeforeMount err.response:', 'color:red;', err.response)

        const errMsg = err.response?.data?.error
        if (errMsg === 'InvalidConfirmCode') {
          this.message = this.$t('InvalidConfirmCode').toString()
        }
      }
      this.loading = false
    } else {
      this.message = this.$t('NoConfirmationCode').toString()
    }
  }
}
