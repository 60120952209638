
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Spinner extends Vue {
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  small!: boolean | undefined
}
